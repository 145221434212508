import React, { useState } from "react";
import useLang from "../../contexts/Language/LanguageContext";
import TextField from "@mui/material/TextField";
import Button from "@mui/material/Button";
import InputAdornment from "@mui/material/InputAdornment";

function getRedirect(amount, trackid, lang) {
  //   return `https://ecommerce.2bi.me/imiboke/PaymentInit.php?amount=${amount}&trackid=${trackid}&langid=${
  //     lang === "mne" ? "sr_ME" : "en_US"
  //   }`;
  return `https://ecommerce.2bi.me/imiboke/PaymentInit.php?amount=${Number(
    amount
  ).toFixed(2)}&trackid=${trackid}`;
}

export default function Donate() {
  const [value, setValue] = useState(2);
  const lang = useLang();
  function onDonate() {
    const url = getRedirect(value, `${Date.now()}`, lang);
    window && window.location && (window.location.href = url);
  }
  function isDisabled() {
    if (!value) return true;
    return value < 2;
  }
  return (
    <div>
      <h1>{lang === "mne" ? "Donirajte" : "Donate"}</h1>
      <p>
        {lang === "mne"
          ? `Ovaj Vaš klik nam mnogo znači! Minimalnom donacijom od 2€ možete
        osigurati da osoba s invaliditetom: ima obezbjeđen pristupačan prevoz,
        dobije pomoć u zadovoljavanju osnovnih životnih potreba, izbori se za
        svoje pravo, dobije podršku u slučaju nasilja... Donirajte i omogućite
        da nastavimo i proširimo podršku koju pružamo osobama s invaliditetom!`
          : `This click of yours means a lot to us! With a minimum donation of €2,
           you can ensure that a person with disability: has access to accessible 
           transportation, receives help in fulfilling basic life needs, exercise for his/her rights, 
           receives support in the event of violence... Donate and enable us to continue and expand the 
           support we provide to people with disabilities! `}{" "}
      </p>
      <div className="flex items-center">
        <TextField
          id="standard-number"
          label={
            lang === "mne" ? (
              <span style={{ color: "white" }}>Doniraj</span>
            ) : (
              <span style={{ color: "white" }}>Donate</span>
            )
          }
          type="number"
          value={value}
          sx={{ input: { color: "white", borderColor: "white" } }}
          InputLabelProps={{
            shrink: true,
          }}
          InputProps={{
            startAdornment: (
              <InputAdornment position="start">
                <span style={{ color: "white" }}> &euro;</span>
              </InputAdornment>
            ),
          }}
          variant="outlined"
          onChange={(e) => setValue(e.target.value)}
        />
        <div className="ml-4 mb-4">
          <Button
            disabled={isDisabled()}
            variant="contained"
            onClick={onDonate}
          >
            {lang === "mne" ? "Donirajte" : "Donate"}
          </Button>
        </div>
      </div>
    </div>
  );
}
